import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const HelpCategories = loadable(() => import('/src/components/Help/HelpCategories'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const Search = loadable(() => import('/src/components/Search/Search'))
const SearchResultsContainer = loadable(() => import('/src/components/Search/SearchResults/SearchResultsContainer'))
const SearchResultsPostList = loadable(() => import('/src/components/Search/SearchResults/SearchResultsPostList'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const HelpCategory = ({ data: { search, post } }) => {
    const [options] = useContext(OptionsContext)

    const postTypeInfo = {
        postType: options.helpCentre.postTypeName,
        postTypeCategoryKey: options.helpCentre.postTypeCategoryKey,
        title: options.helpCentre.title,
        uri: options.helpCentre.relativeUrl,
        hero: {
            eyebrow: options.helpCentre.heroEyebrow,
            title: options.helpCentre.heroTitle
        }
    }

    const breadcrumb = {
        type: 'standard',
        pageTitle: post.name,
        pageParent: {
            node: {
                title: postTypeInfo.title,
                uri: postTypeInfo.uri
            }
        }
    }

    return (
        <LayoutInner>
            <Seo post={post} />

            <main>
                <article itemScope itemType="http://schema.org/Article">
                    <Search
                        postTypeInfo={postTypeInfo}
                        breadcrumb={breadcrumb}
                        headingLevel={'span'}
                        searchData={search}
                    />

                    <SearchResultsContainer size={'narrow'} title={post.name}>
                        <SearchResultsPostList
                            type={'category'}
                            postTypeCategoryKey={postTypeInfo.postTypeCategoryKey}
                            itemsRepeater={post.helpCentre?.nodes}
                        />
                    </SearchResultsContainer>

                    <HelpCategories backgroundColour={'alt'} />
                </article>
            </main>
        </LayoutInner>
    )
}

HelpCategory.propTypes = {
    /**
     * Post object
     */
    data: PropTypes.object.isRequired
}

export default HelpCategory

export const pageQuery = graphql`
    query HelpSingleCategory(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
    ) {
        search: localSearchPages {
            index
            store
        }
        # selecting the current post by id
        post: wpHelpCategory(id: { eq: $id }) {
            id
            slug
            link
            name
            helpCentre {
                nodes {
                    id
                    uri
                    title
                    helpCategories {
                        nodes {
                            name
                            link
                            category {
                                featuredImage {
                                    id
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(width: 35)
                                        }
                                    }
                                    altText
                                    title
                                }
                            }
                        }
                    }
                    seo {
                        metaDesc
                    }
                }
            }

            ...SeoForHelpCategory
        }
    }
`
